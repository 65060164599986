// extracted by mini-css-extract-plugin
export var active = "nav-base-module--active--E2VG9";
export var button = "nav-base-module--button--CPAcP";
export var buttons = "nav-base-module--buttons--6LkbS";
export var callToAction = "nav-base-module--call-to-action--UtmX7";
export var closeButton = "nav-base-module--close-button--bUiVN";
export var content = "nav-base-module--content--oz0Dm";
export var donate = "nav-base-module--donate--uIFJ-";
export var highlighted = "nav-base-module--highlighted--CbaXU";
export var homeButton = "nav-base-module--home-button--ghRkC";
export var language = "nav-base-module--language--UqUMA";
export var logo = "nav-base-module--logo--w-67T";
export var menuButton = "nav-base-module--menu-button--Gz4C4";
export var menuContainer = "nav-base-module--menu-container--PE+vX";
export var open = "nav-base-module--open--9bMjR";
export var overlay = "nav-base-module--overlay--yB7R6";
export var submenu = "nav-base-module--submenu--cueum";