/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */

import React from "react";

import * as style from "./footer.module.css";

export default function Footer({ title, action }) {
  return (
    <section className={style.footer}>
      {(title || action) && (
        <div className={style.promo}>
          <h2>{title}</h2>

          {action && (
            <React.Fragment>
              <a className="action" href={action.link}>
                {action.text}
              </a>
              <p>{action.secondary}</p>
            </React.Fragment>
          )}

          {/* <div className={style.socialButtons}>
          <a
            className="social-button button-fb"
            href="https://www.facebook.com/cvabroad"
            target="_blank"
            rel="noreferrer"
          ></a>
          <a
            className="social-button button-linkedin"
            href="https://www.linkedin.com/company/15789234"
            target="_blank"
            rel="noreferrer"
          ></a>
          <a
            className="social-button button-instagram"
            href="https://instagram.com/cv_abroad"
            target="_blank"
            rel="noreferrer"
          ></a>
        </div> */}
        </div>
      )}

      <div className={style.info}>
        <div className="copyright">
          <a href="https://iconscout.com/icons/upload-in-cloud" target="_blank">
            Upload Icon
          </a>{" "}
          by{" "}
          <a
            href="https://iconscout.com/contributors/amit-jakhu"
            target="_blank"
          >
            Amit Jakhu
          </a>
        </div>
        <div className="copyright">© 2022 Elshad Shirinov</div>

        <div className={style.links}>
          <a className={style.imprint} href="/imprint">
            Imprint
          </a>
          <a className={style.privacy} href="/privacy">
            Privacy Policy
          </a>
          <a className={style.contact} href="mailto:hello@call-a-colleague.eu">
            Contact
          </a>
        </div>
      </div>
    </section>
  );
}

Footer.options = [
  { type: "html", name: "title" },
  {
    type: "object",
    name: "action",
    properties: [
      { type: "string", name: "link" },
      { type: "string", name: "text" },
      { type: "string", name: "secondary" },
    ],
  },
];
