import React from "react";

import translate from "../utils/translate";

import NavBase from "./nav-base.js";

import * as style from "./navigation.module.css";

const STRINGS = {
  common: {
    language_en: "🇬🇧",
    language_ua: "🇺🇦",
  },
  en: {
    about: "Our Story",
    resources: "Career Resources",
    mentors: "For Mentors",
    donate: "Donate",
    home: "Home",
  },
  ua: {
    about: "Наша історія",
    resources: "Кар'єрні ресурси",
    mentors: "Для менторів",
    donate: "€",
    home: "Головна",
  },
};

const makeNavigation = (language) => {
  const t = translate(language, STRINGS);

  return function Navigation({ stub, pathname }) {
    const parts = pathname.split("/");
    const rest = parts[1] === language ? parts.slice(2) : parts.slice(1);
    const hasLanguage = parts[1] === language;
    const baseUrl = "/" + (hasLanguage ? language + "/" : "");

    const items = [
      {
        id: "about",
        text: t("about"),
        href: baseUrl + "about",
      },
      {
        id: "mentors",
        text: t("mentors"),
        href: baseUrl + "mentors",
      },
      {
        id: "resources",
        text: t("resources"),
        href: baseUrl + "resources",
      },
      // {
      //   id: "donate",
      //   text: t("donate"),
      //   href: "https://www.paypal.com/donate/?hosted_button_id=V8CPDUQJQL42L",
      //   target: "_blank",
      //   highlighted: true,
      // },
      // {
      //   id: "resources",
      //   text: t("resources"),
      //   href: "/resources",
      // },
    ];

    if (language)
      items.push({
        id: "languages",
        text: (
          <span className={style.languageButton}>
            {language === "ua" ? t("language_en") : t("language_ua")}
          </span>
        ),
        href: language === "ua" ? "/" : "/ua",
      });

    return (
      <NavBase
        stub={stub}
        homeUrl={baseUrl}
        homeLabel={t("home")}
        donateLabel={t("donate")}
        items={items}
      />
    );
  };
};

export default makeNavigation;
export const en = makeNavigation("en");
export const ru = makeNavigation("ru");
export const ua = makeNavigation("ua");
