import React from "react";
import { useLocation } from "@reach/router";

import Footer from "./footer";
import { en as EnNav, ua as UaNav } from "./navigation";

import "../styles/global.css";
import SEO from "./seo";

// import ukraineImage from "../images/ukraine.png";

// import * as style from "./index.module.css";

const Navs = {
  en: EnNav,
  ua: UaNav,
};

const PageLayout = ({
  title,
  description,
  image,
  language = "en",
  children,
}) => {
  const location = useLocation();

  const Nav = Navs[language];

  return (
    <React.Fragment>
      <SEO
        title={title}
        description={description}
        image={image}
        url={location.pathname}
      />
      <main>
        <Nav pathname={location.pathname} />
        {children}

        <Footer />
      </main>
    </React.Fragment>
  );
};

export default PageLayout;
