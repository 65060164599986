import React from "react";
import cn from "classnames";

import * as style from "./nav-base.module.css";

function SubMenu({ children, selected, onClick }) {
  return (
    <div
      role="menu"
      tabIndex={0}
      className={cn(style.submenu, { [style.open]: selected })}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {children}
    </div>
  );
}

function NavItem({
  href,
  text,
  target,
  className,
  element,
  items,
  selected,
  onExpand,
  onClickSubItem,
}) {
  return (
    <div
      role="menu"
      tabIndex={0}
      className={style.menuContainer}
      onClick={onExpand}
      onKeyDown={onExpand}
    >
      {element ? (
        element
      ) : (
        <a
          className={cn(style.button, className, {
            [style.expandingButton]: !!items,
          })}
          href={href}
          target={target}
        >
          {text}
        </a>
      )}

      {items && (
        <SubMenu selected={selected} onClick={onClickSubItem}>
          {items.map((subItem, index) => (
            <a
              key={index}
              href={subItem.href}
              className={cn(style.button, subItem.className)}
            >
              {subItem.text}
            </a>
          ))}
        </SubMenu>
      )}
    </div>
  );
}

export default function Navigation({
  stub,
  homeUrl,
  homeLabel = "Home",
  donateLabel = "Donate",
  items,
}) {
  const [isOpen, setOpen] = React.useState(false);
  const [openContainer, doSetOpenContainer] = React.useState();

  function setOpenContainer(id) {
    doSetOpenContainer(openContainer === id ? undefined : id);
  }
  function closeMenu() {
    setOpen(false);
  }

  if (stub) {
    return (
      <nav>
        <div className={style.content}>
          <div className={style.content}>
            <a href={homeUrl} className={cn(style.button, style.homeButton)}>
              CV Abroad
            </a>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav>
      <div className={style.content}>
        <a href={homeUrl} className={cn(style.button, style.homeButton)}>
          {homeLabel}
        </a>

        <a
          href="https://www.paypal.com/donate/?hosted_button_id=V8CPDUQJQL42L"
          className={cn(style.button, style.highlighted, style.donate)}
        >
          {donateLabel}
        </a>

        <button
          aria-label="Open Menu"
          className={cn(style.menuButton, { [style.open]: isOpen })}
          onClick={() => {
            setOpen(!isOpen);
          }}
        />

        <div className={cn(style.overlay, { [style.active]: isOpen })} />

        <div
          className={style.buttons}
          style={{ left: isOpen ? null : "calc(100vw + 50px)" }}
        >
          <button
            aria-label="Close Menu"
            className={style.closeButton}
            onClick={closeMenu}
          />

          {items.map((item) => (
            <NavItem
              key={item.id}
              {...item}
              selected={openContainer === item.id}
              onExpand={() => {
                if (!item.items) {
                  closeMenu();
                  return;
                }

                setOpenContainer(item.id);
              }}
              className={cn({ [style.highlighted]: item.highlighted })}
              onClickSubItem={closeMenu}
            />
          ))}
        </div>
      </div>
    </nav>
  );
}
